import React, { memo } from 'react'
import Head from 'next/head'
import isEmpty from 'lodash/isEmpty'

const JsonLDTag = ({
  type,
  name = 'ClikAuto',
  description,
  vdpData = {},
  url = 'https://clikauto.com',
  logo = 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/logo.webp',
  image = 'https://clikauto-static-files.s3.us-east-2.amazonaws.com/production/website/compra-un-auto-seminuevo-con-garantia-o-vende-tu-auto_en_ClikAuto-1.webp',
  telephone = '55 8957 1916',
  showSecondScript = true,
  secondScriptData = '',
  showThirdScript = false,
}) => {
  const schemaData = {
    '@context': 'http://www.schema.org',
    '@type': type,
    name,
    url,
    logo,
    image,
    description,
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Perif. Blvd. Manuel Ávila Camacho 810, Las Margaritas, Tlalnepantla de Baz',
      addressLocality: 'Estado de México',
      addressRegion: 'Tlalnepantla de Baz',
      postalCode: '54050',
      addressCountry: 'México',
    },
    contactPoint: {
      '@type': 'ContactPoint',
      telephone,
    },
  }

  const VDPSchema = {
    '@context': 'https://schema.org',
    '@type': 'Car',
    name: vdpData?.name,
    brand: vdpData?.brand,
    description: vdpData?.description,
    keywords: vdpData?.keywords,
    model: vdpData?.model,
    modelDate: vdpData?.modelDate,
    seatingCapacity: vdpData?.seatingCapacity,
    vehicleInteriorType: vdpData?.vehicleInteriorType,
    bodyType: vdpData?.bodyType,
    Image: vdpData?.image,
    mileageFromOdometer: vdpData?.mileageFromOdometer,
    vehicleTransmission: vdpData?.vehicleTransmission,
    fuelType: vdpData?.fuelType,
    color: vdpData?.color,
    offers: vdpData?.offers,
  }
  const data = {
    __html: JSON.stringify(isEmpty(vdpData) ? schemaData : VDPSchema),
  }

  const secondData = {
    __html: JSON.stringify(secondScriptData),
  }
  const thirdSchemaData = {
    '@context': 'http://www.schema.org',
    '@type': 'WebSite',
    name: 'ClikAuto',
    alternateName: 'ClikAuto',
    url: 'https://clikauto.com',
  }
  const thirdData = {
    __html: JSON.stringify(thirdSchemaData),
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={data}
        key="first-jsonld"
      />

      {
        showSecondScript && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={secondData}
            key="second-jsonld"
          />
        )
      }

      {
        showThirdScript && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={thirdData}
            key="third-jsonld"
          />
        )
      }

    </Head>
  )
}

export default memo(JsonLDTag)
